<template>
  <!-- Selected group's settings page -->
  <div class="tab-content">

    <!-- Name of selected group -->
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0">{{ printName() }}</h3>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11">
        <hr>
      </b-col>
    </b-row>

    <!-- Checkbox settings of selected group -->
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">{{ $t('groupManagement.settings') }}</h3>
      </b-col>
    </b-row>
    <b-row v-if="isSysAdmin">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex">
        <div class="mb-2 border-red">
          <b-form-checkbox
            id="disable-account"
            name="disableAccount"
            size="lg"
            v-model="group.settings.disableAccount"
            :value="true"
            :unchecked-value="false"
            @change="disableAccountBox"
          >
            {{ $t('groupManagement.disableAccountLabel') }}
          </b-form-checkbox>
          <b-form-text>
            {{ $t('groupManagement.disableAccountDesc') }}
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isSysAdmin">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex">
        <div class="mb-2 border-red">
          <b-form-checkbox
            id="is-test-account"
            name="isTestAccount"
            size="lg"
            v-model="group.settings.isTestAccount"
            :value="true"
            :unchecked-value="false"
            @change="changeTestAccountSettings"
          >
            {{ $t('groupManagement.testAccountLabel') }}
          </b-form-checkbox>
          <b-form-text>
            {{ $t('groupManagement.testAccountDesc') }}
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isSysAdmin">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex">
        <div class="mb-2">
          <b-form-checkbox
            id="enable-custom-fonts"
            name="enableCustomFonts"
            size="lg"
            v-model="group.settings.enableCustomFonts"
            :value="true"
            :unchecked-value="false"
            @change="enableCustomFonts"
          >
            {{ $t('groupManagement.enableCustomFontsLabel') }}
          </b-form-checkbox>
          <b-form-text>
            {{ $t('groupManagement.enableCustomFontsDesc') }}
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isSysAdmin" class="mb-4">
      <b-col cols="12" offset-sm="1" sm="7" class="d-flex align-items-center mb-2">
        <modal-create-and-edit-custom-fonts
          v-if="group.settings.enableCustomFonts"
          :group="group"
          :customFont="null"
          :tableIndex="null"
          :edit="false"
          @addCustomFont="addCustomFont"
        ></modal-create-and-edit-custom-fonts>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="11">
        <div
          v-if="group.settings.enableCustomFonts
          && group.settings.customFonts && group.settings.customFonts.length !== 0"
        >
          <b-table
            :fields="fontFields"
            :items="group.settings.customFonts"
            :key="number"
            ref="fontTable"
            sort-icon-left
            show-empty
            borderless
            dark
            class="table-view fonts-table"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
              >
            </template>
            <template #cell(image) class="test">
              <b-icon
                icon="paperclip"
                style="color: #b9b9b9;"
                class="mr-2" aria-hidden="true">
              </b-icon>
            </template>
            <template #cell(name)="data">
              <div
                class="item"
                @click="$emit('selected', data.item)"
              >
                {{ data.item.fontFamily }}
              </div>
            </template>
            <template #cell(font_weight)="data">
              <div
                class="item"
                @click="$emit('selected', data.item)"
              >
                {{ data.item.fontWeight }}
              </div>
            </template>
            <template #cell(font_style)="data">
              <div
                class="item"
                @click="$emit('selected', data.item)"
              >
                {{ data.item.style }}
              </div>
            </template>
            <template #cell(font_display)="data">
              <div
                class="item"
                @click="$emit('selected', data.item)"
              >
                {{ data.item.fontDisplay }}
              </div>
            </template>
            <template #cell(font_fallback)="data">
              <div
                class="item"
                @click="$emit('selected', data.item)"
              >
                {{ data.item.fallbackFont }}
              </div>
            </template>
            <template #cell(dot)="data">
              <b-dropdown
                class="context-menu"
                toggle-class="dot-button"
                no-caret
                dropup
                no-flip
                right
              >
                <template #button-content>
                  <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
                </template>
                <b-dropdown-item
                  v-b-modal="`modal-edit-custom-font-group-${group.group_id}-${data.index}`"
                >
                  {{ $t('modals.editCustomFont.dropdown') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal="`modal-delete-custom-font-${data.index}`"
                >
                  {{ $t('modals.deleteCustomFont.dropdown') }}
                </b-dropdown-item>
              </b-dropdown>
              <modal-create-and-edit-custom-fonts
                v-if="group.settings.enableCustomFonts"
                :group="group"
                :customFont="data.item"
                :tableIndex="data.index"
                :edit="true"
                @editCustomFont="editCustomFont"
              ></modal-create-and-edit-custom-fonts>
              <modal-delete-custom-font
                :customFont="data.item"
                :tableIndex="data.index"
                @deleteCustomFont="deleteCustomFont"
              ></modal-delete-custom-font>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>

    <!-- Custom domain settings of selected group -->
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="11" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">{{ $t('groupManagement.customDomain') }}</h3>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="7" class="d-flex align-items-center">
        <b-form-input
            id="custom-domain"
            :style="(isSysAdmin ? { border: '1px solid rgba(220, 83, 83, 0.7)' } : '')"
            :disabled="!isSysAdmin && group.user_role !== 'accountManager'"
            v-model="domain"
            aria-describedby="custom-domain-help-block"
            debounce="500"
        ></b-form-input>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="7" class="d-flex align-items-center mt-2">
        <modal-add-custom-domain
            :group="group"
            :initialDomain="group.settings.customDomain"
            :isSysAdmin="isSysAdmin"
            @addCustomDomain="addCustomDomain"
        ></modal-add-custom-domain>
        <b-button
          v-if="isSysAdmin || group.user_role === 'accountManager'"
          @click="saveCustomDomain(domain)"
          class="red-button-transparent ml-3"
        >
          {{ $t('buttons.saveDomain') }}
        </b-button>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="7" class="d-flex align-items-center">
        <b-form-text
            v-if="group.settings.customDomain !== '' &&
            group.settings.customDomain !== group.domain"
            id="custom-domain-help-block"
        >
          {{ $t('groupManagement.customDomainInfo', { domain: group.settings.customDomain }) }}
          <b-icon
              icon="exclamation-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
              v-b-modal="'modal-custom-domain-info-group-'+group.group_id"
          >
          </b-icon>
          <modal-custom-domain-info :group="group"></modal-custom-domain-info>
        </b-form-text>
      </b-col>
    </b-row>

    <!-- List of child groups of selected group -->
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('groupManagement.childGroups') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right d-flex">
          <b-input-group class="search-group d-flex align-items-center">
            <b-icon
                icon="search"
                aria-hidden="true"
                scale="1"
            ></b-icon>
            <b-form-input
                id="filter-input"
                v-model="childGroupsFilter"
                type="search"
                :placeholder="$t('webmag.search')"
            ></b-form-input>
          </b-input-group>
          <modal-add-new-group
              v-if="canCreateSubgroups"
              :tableIndex="group.group_id"
              :isSysAdmin="isSysAdmin"
              :userPrivileges="userPrivileges"
              :groupCreateError="groupCreateError"
              @createGroup="createGroup"
              @resetErrorMsg="resetErrorMsg"
          ></modal-add-new-group>
          <modal-group-create-info
            v-if="showGroupCreateInfo && !isSysAdmin"
            @close-info-modal="showGroupCreateInfo = false"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
            :fields="groupFields"
            :items="group.children"
            :key="number"
            :filter="childGroupsFilter"
            sort-icon-left
            show-empty
            borderless
            dark
            class="table-view items-table"
        >
          <template #empty>
            <div class="text-center my-2">
              <b-icon
                  icon="exclamation-circle-fill"
                  class="mr-2 top--3"
                  aria-hidden="true"
                  scale="1"
              ></b-icon>
              {{ $t('table.noChildGroups') }}
            </div>
          </template>
          <template #emptyfiltered>
            <div class="table-center my-2">
              <b-icon
                  icon="exclamation-circle-fill"
                  class="mr-2 top--3"
                  aria-hidden="true"
                  scale="1"
              ></b-icon>
              {{ $t('table.noFilteredGroups') }}
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
            >
          </template>
          <template #cell(name)="data">
            <div
                class="item"
                @click="$emit('selected', data.item)"
            >
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(dot)="data">
            <b-dropdown
                v-if="isSysAdmin"
                class="context-menu"
                toggle-class="dot-button"
                :disabled="!isSysAdmin && !$hasPrivileges(
                  $privileges('CAN_ADD_SUBGROUP_TO_GROUP'),
                  isSysAdmin,
                  userPrivileges)"
                no-caret
                dropup
                no-flip
                right
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
              </template>
              <b-dropdown-item
                  v-b-modal="`modal-remove-group-${data.item.group_id}`"
              >
                {{ $t('modals.removeGroup.dropdown') }}
              </b-dropdown-item>
            </b-dropdown>
            <modal-remove-group
                class="d-inline-block"
                :groupIndex="data.item.group_id"
                :groupName="data.item.name"
                :groupChildren="data.item.children"
                @removeGroup="removeGroup"
            ></modal-remove-group>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- List of users of selected group -->
    <b-row class="mb-2">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase">
          {{ $t('groupManagement.groupUsers') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right d-flex">
          <b-input-group class="search-group d-flex align-items-center">
            <b-icon
                icon="search"
                aria-hidden="true"
                scale="1"
            ></b-icon>
            <b-form-input
                id="filter-input"
                v-model="groupUserFilter"
                type="search"
                :placeholder="$t('webmag.search')"
            ></b-form-input>
          </b-input-group>
          <b-button
            @click="showAddUserModal = true"
            class="green-button-transparent border-0 ml-3"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            {{ $t('buttons.addMember') }}
          </b-button>
          <modal-add-new-user
              v-if="showAddUserModal"
              :isSysAdmin="isSysAdmin"
              :userPrivileges="userPrivileges"
              :current-user-role="group.user_role"
              :inTeam="false"
              @addUser="addUser"
              @cancel="showAddUserModal = false"
          ></modal-add-new-user>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
            @sort-changed="handleSortChange"
            :fields="userFields"
            :items="users"
            :filter="groupUserFilter"
            :tbody-tr-class="rowClass"
            sort-icon-left
            show-empty
            borderless
            dark
            class="table-view users-table"
        >
          <template #empty>
            <div class="text-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noUsers') }}
            </div>
          </template>
          <template #emptyfiltered>
            <div class="table-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noFilteredUsers') }}
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data" class="test">
            <b-avatar
                v-if="data.item.image !== null"
                size="32px"
                :src="data.item.image"
            ></b-avatar>
            <b-avatar v-else size="32px"></b-avatar>
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(email)="data">
            {{ data.item.email }}
          </template>
          <template #cell(role)="data">
            {{ data.item.role.name }}
          </template>
          <template #cell(status)="data">
            {{ data.item.status }}
          </template>
          <template #cell(dot)="data">
            <user-management-dropdown
                :key="number"
                :data="data"
                :tableIndex="group.group_id"
                :group="group"
                :isSysAdmin="isSysAdmin"
                :current-user-id="currentUserId"
                :userPrivileges="userPrivileges"
                :teamIndex="group.group_id"
                :userObject="data.item"
                :users="users"
                @editUser="editUser"
                @deleteUser="deleteUser"
                @activateUser="activateUser"
                @sendInvitationReminder="sendInvitationReminder"
            ></user-management-dropdown>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- List of users in teams of selected group but not in said group
    (table only visible to sys_admin users) -->
    <b-row v-if="isSysAdmin" class="mb-2 team-member">
      <b-col cols="12" offset-sm="1" sm="3" class="d-flex align-items-center">
        <h3 class="mb-0 text-uppercase"
            v-bind:style="{ color: 'rgba(220, 83, 83, 0.7)' }">
          {{ $t('groupManagement.teamUsers') }}
        </h3>
      </b-col>
      <b-col cols="12" sm="8" class="align-content-end">
        <div class="float-right d-flex">
          <b-input-group class="search-group d-flex align-items-center">
            <b-icon
                icon="search"
                aria-hidden="true"
                scale="1"
            ></b-icon>
            <b-form-input
                id="filter-input"
                v-model="teamUserFilter"
                type="search"
                :placeholder="$t('webmag.search')"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isSysAdmin" class="mb-5 team-member">
      <b-col cols="12" offset-sm="1" sm="11">
        <b-table
            :fields="userTeamFields"
            :items="teamUsers"
            :key="number"
            :filter="teamUserFilter"
            sort-icon-left
            show-empty
            borderless
            dark
            class="table-view users-table"
        >
          <template #empty>
            <div class="text-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noUsers') }}
            </div>
          </template>
          <template #emptyfiltered>
            <div class="table-center my-2">
              <b-icon
                icon="exclamation-circle-fill"
                class="mr-2 top--3"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('table.noFilteredUsers') }}
            </div>
          </template>
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.width }"
            >
          </template>
          <template #cell(image)="data" class="test">
            <b-avatar
                v-if="data.item.image !== null"
                size="32px"
                :src="data.item.image"
            ></b-avatar>
            <b-avatar v-else size="32px"></b-avatar>
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(email)="data">
            {{ data.item.email }}
          </template>
          <template #cell(role)="data">
            {{ data.item.team }}
          </template>
          <template #cell(status)="data">
            {{ data.item.status }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UpdatePirschDomain from '@/graphQlQueries/mutations/updatePirschDomain';
import GetAllUsersOfGroup from '@/graphQlQueries/queries/getAllUsersOfGroup';
import GetAllTeamUsersOfGroup from '@/graphQlQueries/queries/getAllTeamUsersOfGroup';
import UserDataAndAccess from '@/mixins/userDataAndAccess';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';
import DuplicateSharedTemplate from '@/mixins/duplicateSharedTemplate';
import SendInviteEmail from '@/graphQlQueries/mutations/sendInviteEmail';
import SendNewCustomDomainEmail from '@/graphQlQueries/mutations/sendNewCustomDomainEmail';
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import UpdateUserActivate from '@/graphQlQueries/mutations/updateUserActivate';
import CreateGroup from '@/graphQlQueries/mutations/createGroup';
import AddGroupMember from '@/graphQlQueries/mutations/addGroupMember';
import GetGroupObjectByGroupId from '@/graphQlQueries/queries/getGroupObjectByGroupId';
import GetUserByEmail from '@/graphQlQueries/queries/getUserByEmail';
import DeleteGroupAndSubGroups from '@/graphQlQueries/mutations/deleteGroupAndSubGroups';
import UpdateGroupSettings from '@/graphQlQueries/mutations/updateGroupSettings';
import UpdateCustomDomain from '@/graphQlQueries/mutations/updateCustomDomain';
import DeleteCacheFetchMagazine from '@/graphQlQueries/mutations/deleteCacheFetchMagazine';
import corporateIdentityDefaultFolderData from '@/defaultData/corporateIdentityDefaultFolderData';

export default {
  name: 'GroupListView',
  components: {
    ModalAddCustomDomain: () => import('@/components/modals/ModalAddCustomDomain.vue'),
    ModalCustomDomainInfo: () => import('@/components/modals/ModalCustomDomainInfo.vue'),
    ModalAddNewUser: () => import('@/components/modals/ModalAddNewUser.vue'),
    ModalGroupCreateInfo: () => import('@/components/modals/ModalGroupCreateInfo.vue'),
    UserManagementDropdown: () => import('@/components/settings/groups/UserManagementDropdown.vue'),
    ModalAddNewGroup: () => import('@/components/modals/ModalAddNewGroup.vue'),
    ModalRemoveGroup: () => import('@/components/modals/ModalRemoveGroup.vue'),
    ModalCreateAndEditCustomFonts: () => import('@/components/modals/ModalCreateAndEditCustomFonts.vue'),
    ModalDeleteCustomFont: () => import('@/components/modals/ModalDeleteCustomFont.vue'),
  },
  mixins: [UserDataAndAccess, SendPublishedDataToServer, DuplicateSharedTemplate],
  props: ['group', 'isSysAdmin', 'userPrivileges', 'currentUserId', 'currentUserEmail'],
  created() {
    this.domain = (this.group.domain) ? this.group.domain : null;
    this.oldDomain = (this.group.domain) ? this.group.domain : null;
    console.log('group', this.group);
  },
  watch: {
    group: {
      handler() {
        this.domain = (this.group.domain) ? this.group.domain : null;
        this.oldDomain = (this.group.domain) ? this.group.domain : null;
      },
      deep: true,
    },
  },
  computed: {
    canCreateSubgroups() {
      return this.$hasPrivileges(
        this.$privileges('CAN_ADD_SUBGROUP_TO_GROUP'),
        this.isSysAdmin,
        this.userPrivileges,
      );
    },
  },
  data() {
    return {
      users: [],
      teamUsers: [],
      domain: null,
      groupCreateError: null,
      groupUserFilter: null,
      teamUserFilter: null,
      childGroupsFilter: null,
      showAddUserModal: false,
      showGroupCreateInfo: false,
      number: 342,
      number2: 1,
      showTable: true,
      oldDomain: null,
      userFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('groupManagement.table.name'),
          sortable: true,
          width: '150',
          tdClass: 'name_column',
        },
        {
          key: 'email',
          label: this.$t('groupManagement.table.email'),
          sortable: true,
          width: 'auto',
          tdClass: 'email_column',
        },
        {
          key: 'role',
          label: this.$t('groupManagement.table.role'),
          sortable: true,
          width: '90px',
          tdClass: 'role_column',
        },
        {
          key: 'status',
          label: this.$t('groupManagement.table.status'),
          sortable: true,
          width: '75px',
          tdClass: 'status_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      userTeamFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('groupManagement.table.name'),
          sortable: true,
          width: '150',
          tdClass: 'name_column',
        },
        {
          key: 'email',
          label: this.$t('groupManagement.table.email'),
          sortable: true,
          width: 'auto',
          tdClass: 'email_column',
        },
        {
          key: 'team',
          label: 'Team',
          sortable: true,
          width: '100px',
        },
        {
          key: 'role',
          label: this.$t('groupManagement.table.role'),
          sortable: true,
          width: '90px',
          tdClass: 'role_column',
        },
        {
          key: 'status',
          label: this.$t('groupManagement.table.status'),
          sortable: true,
          width: '75px',
          tdClass: 'status_column',
        },
      ],
      groupFields: [
        {
          key: 'id',
          label: '',
          sortable: false,
          width: '1px',
        },
        {
          key: 'name',
          label: this.$t('groupManagement.table.name'),
          sortable: true,
          width: 'auto',
          tdClass: 'name_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
      fontFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          tdClass: 'image_column',
        },
        {
          key: 'name',
          label: this.$t('groupManagement.table.name'),
          sortable: false,
          width: '150px',
          tdClass: 'name_column',
        },
        {
          key: 'font_weight',
          label: this.$t('groupManagement.table.font_weight'),
          sortable: false,
          width: 'auto',
          tdClass: 'font_weight_column',
        },
        {
          key: 'font_style',
          label: this.$t('groupManagement.table.font_style'),
          sortable: false,
          width: 'auto',
          tdClass: 'font_style_column',
        },
        {
          key: 'font_display',
          label: this.$t('groupManagement.table.font_display'),
          sortable: false,
          width: 'auto',
          tdClass: 'font_display_column',
        },
        {
          key: 'font_fallback',
          label: this.$t('groupManagement.table.font_fallback'),
          sortable: false,
          width: 'auto',
          tdClass: 'font_fallback_column',
        },
        {
          key: 'dot',
          label: '',
          sortable: false,
          width: '40px',
        },
      ],
    };
  },
  apollo: {
    users: {
      variables() {
        return {
          gid: this.group.group_id,
        };
      },
      query: GetAllUsersOfGroup,
      update(data) {
        const userObjects = [];
        const userIds = [];

        // Retrieves all users in selected group and stores them in userObjects
        // as well as stores their ids in userIds
        data.users_roles_groups.forEach((userItem) => {
          const showUser = this.isSysAdmin || this.$currentUserRole === 'accountManager';
          if (userItem.role.handle !== 'accountManager' || showUser) {
            const element = {
              userId: userItem.user.user_id,
              name: userItem.user.full_name,
              email: userItem.user.email,
              role: {
                role_id: userItem.role.role_id,
                name: userItem.role.name,
                handle: userItem.role.handle,
                description: userItem.role.description,
              },
              deactivated: userItem.user.deactivated,
              active: userItem.user.active,
              // eslint-disable-next-line no-nested-ternary
              status: userItem.user.deactivated ? this.$t('teamManagement.deactivated') : (userItem.user.email_confirmed ? this.$t('teamManagement.online') : this.$t('teamManagement.invited')),
            };
            element.image = (userItem.user.profile_pictures)
              ? this.getImageUrl(userItem.user.profile_pictures)
              : null;
            if ((element.role.name === 'Account Manager' && (this.isSysAdmin || this.$hasPrivileges(this.$privileges('CAN_SEE_ACCOUNT_MANAGERS'), this.isSysAdmin, this.userPrivileges))) || element.role.name !== 'Account Manager') {
              userObjects.push(element);
            }
            userIds.push(userItem.user.user_id);
          }
        });
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
        // sort the userObjects first after the status desc and after the role_id in the role object
        userObjects.sort((a, b) => {
          if (a.status > b.status) {
            return -1;
          }
          if (a.status < b.status) {
            return 1;
          }
          if (a.role.role_id < b.role.role_id) {
            return -1;
          }
          if (a.role.role_id > b.role.role_id) {
            return 1;
          }
          return 0;
        });
        return userObjects;
      },
      fetchPolicy: 'network-only',
    },
    teamUsers: {
      variables() {
        return {
          gid: this.group.group_id,
        };
      },
      query: GetAllTeamUsersOfGroup,
      update(data) {
        const teamUsers = [];
        // For each item (folder, project or page) in teams_items belonging to selected group,
        // retrieves teams with all their users and stores them in teamUsers
        // the team users will only be displayed for sys_admin users
        data.teams_items.forEach((teamItem) => {
          const { team } = teamItem;
          if (team.users_roles_teams) {
            team.users_roles_teams.forEach((user) => {
              // user not a group user AND
              // user not already in teamUsers
              if (!teamUsers.includes(user.user.user_id)) {
                const element = {
                  userId: user.user.user_id,
                  name: user.user.full_name,
                  email: user.user.email,
                  role: user.role.name,
                  deactivated: user.user.deactivated,
                  active: user.user.active,
                  team: team.name,
                  // eslint-disable-next-line no-nested-ternary
                  status: user.user.deactivated ? this.$t('groupManagement.deactivated') : (user.user.email_confirmed ? this.$t('groupManagement.online') : this.$t('groupManagement.invited')),
                };
                element.image = (user.user.profile_pictures)
                  ? this.getImageUrl(user.user.profile_pictures)
                  : null;
                teamUsers.push(element);
              }
            });
          }
        });
        return teamUsers;
      },
      skip() {
        return !this.isSysAdmin;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    handleSortChange() {
      console.log('sort change...');
      this.$root.$emit('bv::refresh::table', 'table');
    },
    async addCustomDomain(group) {
      this.group.settings = group.settings;
      await this.$apollo.mutate({
        mutation: UpdateGroupSettings,
        variables: {
          group_id: group.group_id,
          settings: group.settings,
        },
      });
      // Send email
      await this.$apollo.mutate({
        mutation: SendNewCustomDomainEmail,
        variables: {
          subject: this.$t('groupManagement.email.newCustomDomain.subject'),
          to: this.$t('groupManagement.email.newCustomDomain.email'),
          html: this.$t('groupManagement.email.newCustomDomain.text', {
            user_email: this.userEmail,
            group_name: group.name,
            group_domain: group.settings.customDomain,
          }),
        },
      });
      this.$bvToast.toast(this.$t('groupManagement.email.newCustomDomainToast.title'), {
        title: this.$t('groupManagement.email.newCustomDomainToast.text'),
        solid: true,
      });
      this.$emit('updateCustomDomain', this.group);
    },
    async saveCustomDomain(custom_domain) {
      console.log('saving domain...');
      let publishedDataWasSuccessfulSend = null;
      try {
        publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'updateRootFolder',
          data: {
            groupSlug: this.group.slug,
            groupDomain: custom_domain,
            oldGroupDomain: this.oldDomain,
          },
        });
      } catch (ex) {
        console.log(ex);
      }
      if (publishedDataWasSuccessfulSend) {
        try {
          if (this.group.pirsch_code) {
            await this.$apollo.mutate({
              mutation: UpdatePirschDomain,
              variables: {
                obj: {
                  domain: custom_domain,
                  pirsch_code: this.group.pirsch_code,
                },
              },
            });
          }
        } catch (ex) {
          console.log('Pirsch could not be updated!', ex);
        }
        try {
          this.group.settings.customDomain = '';
          await this.$apollo.mutate({
            mutation: UpdateCustomDomain,
            variables: {
              group_id: this.group.group_id,
              settings: this.group.settings,
              custom_domain,
            },
          });
          const domain = this.oldDomain;
          await this.$apollo.mutate({
            mutation: DeleteCacheFetchMagazine,
            variables: {
              domain: (domain[domain.length - 1] === '/') ? domain : `${domain}/`,
              slug_path: '',
            },
          });

          this.oldDomain = custom_domain;
          this.$emit('saveCustomDomain', this.group.settings, custom_domain);
        } catch (ex) {
          console.log(ex);
        }
      }
    },
    disableAccountBox() {
      this.updateGroupSettings();
    },
    changeTestAccountSettings() {
      this.updateGroupSettings();
    },
    enableCustomFonts() {
      this.updateGroupSettings();
    },
    addCustomFont(customFont) {
      this.group.settings.customFonts = [...this.group.settings.customFonts, customFont];
      this.updateGroupSettings();
    },
    editCustomFont(customFont, tableIndex) {
      this.group.settings.customFonts[tableIndex] = customFont;
      this.$refs.fontTable.refresh();
      this.updateGroupSettings();
    },
    deleteCustomFont(tableIndex) {
      this.group.settings.customFonts.splice(tableIndex, 1);
      this.updateGroupSettings();
    },
    updateGroupSettings() {
      this.$apollo.mutate({
        mutation: UpdateGroupSettings,
        variables: {
          group_id: this.group.group_id,
          settings: this.group.settings,
        },
      }).then(() => {
        this.$emit('updateGroupSettings', this.group);
      });
    },
    async addUser(name, email, role) {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.showAddUserModal = false;
      try {
        const userNameEmail = (this.userObject.name) ? this.userObject.name : this.userObject.email;
        const { data: { users } } = await this.$apollo.query({
          query: GetUserByEmail,
          variables: {
            email,
          },
        });
        await this.$apollo.mutate({
          mutation: AddGroupMember,
          variables: {
            obj: {
              full_name: name,
              group_id: this.group.group_id,
              role_id: role.role_id,
              email,
            },
          },
        });
        if (users.length === 0) {
          // Send email
          await this.$apollo.mutate({
            mutation: SendInviteEmail,
            variables: {
              subject: this.$t('groupManagement.email.invite.subject'),
              to: email,
              html: this.$t('groupManagement.email.invite.text', {
                group: this.group.name,
                email,
                name: encodeURIComponent(name),
                user_email: userNameEmail,
              }),
            },
          });
          this.$bvToast.toast(this.$t('groupManagement.email.inviteToast.title', { email }), {
            title: this.$t('groupManagement.email.inviteToast.text'),
            solid: true,
          });
        } else {
          const { groupId } = this;
          const data = await this.$apollo.query({
            query: GetGroupObjectByGroupId,
            variables: {
              groupId,
            },
          });
          // Send email
          await this.$apollo.mutate({
            mutation: SendInviteEmail,
            variables: {
              subject: this.$t('groupManagement.email.notify.subject'),
              to: email,
              html: this.$t('groupManagement.email.notify.text', {
                group_name: data.data.groups[0].name,
                group: this.group.name,
                email,
                name: encodeURIComponent(name),
                user_email: userNameEmail,
              }),
            },
          });
          this.$bvToast.toast(this.$t('groupManagement.email.notifyToast.title', { email }), {
            title: this.$t('groupManagement.email.notifyToast.text'),
            solid: true,
          });
        }
      } catch (ex) {
        console.error(ex.message);
      }
      try {
        await this.$apollo.queries.users.refetch();
      } catch (ex) {
        console.error(ex);
      }
      await this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: false,
        },
      });
    },
    getImageUrl(imageObject) {
      let cloudImageUrl = null;
      if (imageObject?.url) {
        cloudImageUrl = this.$makeCloudImage(
          imageObject.url,
          {
            width: 32,
            height: 32,
          },
          imageObject,
          null,
          true,
        );
      }
      return (imageObject?.url)
        ? cloudImageUrl
        : null;
    },
    async sendInvitationReminder(email, name) {
      try {
        await this.$apollo.mutate({
          mutation: SendInviteEmail,
          variables: {
            subject: this.$t('groupManagement.email.reminder.subject'),
            to: email,
            html: this.$t('groupManagement.email.reminder.text', {
              email,
              group: this.group.name,
              name: encodeURIComponent(name),
              user_email: this.userEmail,
            }),
          },
        });
        this.$bvToast.toast(this.$t('groupManagement.email.reminderToast.title', { email }), {
          title: this.$t('groupManagement.email.reminderToast.text'),
          solid: true,
        });
      } catch (ex) {
        console.error(ex);
      }
    },
    async activateUser(userId) {
      try {
        await this.$apollo.mutate({
          mutation: UpdateUserActivate,
          variables: {
            userId,
          },
        });
        await this.$apollo.queries.users.refresh();
      } catch (ex) {
        console.error(ex);
      }
    },
    async editUser() {
      this.showTable = false;
      await this.$apollo.queries.users.refresh();
      this.number++;
      this.number2++;
      this.showTable = true;
    },
    async deleteUser() {
      await this.$apollo.queries.users.refresh();
      this.number++;
    },
    isLastAdmin() {
      let admins = 0;
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].role.name === 'Administrator') {
          admins++;
        }
      }
      if (admins > 1) {
        return false;
      }
      return true;
    },
    printName() {
      if (this.group !== null) {
        return this.group.name;
      }
      return '';
    },
    resetErrorMsg() {
      this.groupCreateError = null;
    },
    async createGroup(newGroupName, groupSlug, groupDomain, modalRef) {
      await this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      try {
        // get the administrator role_id
        const dataForServer = {
          action: 'createGroup',
          data: {
            group_slug: groupSlug,
          },
        };
        const publishedDataWasSuccessfulSend = await this
          .sendPublishedDataToServer(dataForServer);
        if (publishedDataWasSuccessfulSend) {
          try {
            const { role_id } = this.allAvailablePrivileges.find((role) => role.handle === 'administrator');
            // create group
            const {
              data: {
                createGroup: {
                  group_id,
                },
              },
            } = await this.$apollo.mutate({
              mutation: CreateGroup,
              variables: {
                obj: {
                  parent_group_id: this.group.group_id,
                  name: newGroupName,
                  slug: groupSlug,
                  domain: groupDomain,
                  corporate_identity: JSON.stringify(corporateIdentityDefaultFolderData),
                },
              },
            });
            // add user
            await this.$apollo.mutate({
              mutation: AddGroupMember,
              variables: {
                obj: {
                  email: this.userEmail,
                  user_id: null,
                  role_id,
                  group_id,
                },
              },
            });
            await this.duplicateWhitePaperProject(group_id);
            // don't send email on local env
            if (process.env.NODE_ENV !== 'development') {
              await this.$apollo.mutate({
                mutation: SendInviteEmail,
                variables: {
                  subject: `Groupmanagement :: CreateNewGroup :: ${newGroupName}`,
                  to: 'no-reply@webmag.io',
                  html: `New group created by ${this.userEmail}<br><br>
Gruppe: ${newGroupName}<br>
Slug: ${groupSlug}<br>
Domain: ${groupDomain}`,
                },
              });
            }
            this.groupCreateError = null;
            await this.$apollo.mutate({
              mutation: SetLoadingIndicator,
              variables: {
                isIndicatorLoading: false,
              },
            });
            this.$root.$emit('bv::hide::modal', modalRef);
            const toastText = this.$t('groupManagement.email.createGroupToast.text', {
              group: newGroupName,
              slug: groupSlug,
            });
            this.$bvToast.toast(toastText, {
              title: this.$t('groupManagement.email.createGroupToast.title'),
              solid: true,
            });
            // send email to webmag that a group was created
            // Send email
            this.$emit('createGroup', this.group);
            this.showGroupCreateInfo = true;
          } catch (e) {
            console.error('create group error', e);
            await this.$apollo.mutate({
              mutation: SetLoadingIndicator,
              variables: {
                isIndicatorLoading: false,
              },
            });
            this.groupCreateError = this.$t('groupManagement.duplicateNameWithParam', { groupName: newGroupName });
          }
        } else {
          console.error('it was not possible to create a group on the server!');
        }
      } catch (ex) {
        console.error(ex);
        await this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      }
    },
    async removeGroup(groupIndex, typedName) {
      await this.$apollo.mutate({
        mutation: DeleteGroupAndSubGroups,
        variables: {
          group_id: groupIndex,
          name: typedName,
        },
      });
      this.$emit('deleteGroup', this.group);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return;
      }
      if (item.role.name === 'Account Manager') {
        // eslint-disable-next-line consistent-return
        return 'acc_man';
      }
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.custom-fonts-list {
  color: $settings-form-text;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;

  span {
    cursor: pointer;
    svg {
      position: relative;
      top: 1px;
    }
  }
}

hr {
  border-top-color: #8E9093;
  border-top-style: solid;
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
     color: #fff;
  }
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

/deep/ .custom-control-label {
  font-size: 13px;
  font-weight: 700;
  color: #abaaaa;
  letter-spacing: 1px;
}

.search-group {
  width: 150px;
  background-color: #5b6064;
  padding-left: 11px;
}

#content #filter-input, #content #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: calc(1.5em + 0.75rem)
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

.cell-background-image {
  width: 100%;
  height: 100%;
  min-height: 36px;
  min-width: 50px;
  background-color: #777C7F;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.table-dark {
  background-color: transparent;
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;
  color: $webmag-light-grey;

  thead th {
    text-transform: uppercase;
    font-size: 13px;
    padding: 7px 8px;
  }

  tbody tr.acc_man {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;
      border-top: 1px solid rgba(220, 83, 83, 0.7);
      border-bottom: 1px solid rgba(220, 83, 83, 0.7);

      &:first-child {
        padding: 0;
        vertical-align: middle;
        text-align: center;
        border-left: 1px solid rgba(220, 83, 83, 0.7);
      }

      &:last-child {
        xdisplay: none;
        padding: 0;
        border-right: 1px solid rgba(220, 83, 83, 0.7);

        button {
          width: 40px;
          min-height: 36px;
        }
      }
    }
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
        vertical-align: middle;
        text-align: center;
      }

      &:last-child {
        xdisplay: none;
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }
    }
  }
}

.alert-message {
  border: 1px solid #A05B5B;
}

.item {
  cursor: pointer;
}

.border-red{
  border: 1px solid rgba(220, 83, 83, 0.7);
}

.team-member {
  .search-group {
    border: 1px solid rgba(220, 83, 83, 0.7);
  }
  table {
    border: 1px solid rgba(220, 83, 83, 0.7);
    /deep/ td.status_column {
      padding: 7px 8px !important;
    }
  }
}
.red-button-transparent {
  background-color: #5b6064;
  border: 1px solid rgba(220, 83, 83, 0.7);

  &:hover {
    background-color: #DC5353;
    color: #fff;
  }
}
</style>
